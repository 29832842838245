@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.campaignLayout {
  background-color: #f9f9f9; /* subtle background color */
  min-height: 100vh;
  display: flex;
}

.content {
  min-height: 280px;
}

.userContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 0;
  background-color: #fff;
  min-height: 50px;
  max-height: 50px;
}

.welcomeText {
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  color: #333;
  margin: 0;
  padding: 2px 0;
  font-size: 12pt;
}

.textContainer {
  flex: 1; /* Allows the text container to grow and shrink as needed */
  display: flex;
  justify-content: flex-start; /* Aligns the welcome text to the start of the flex container */
}


.actionButton {
  font-family: 'Roboto', sans-serif;
  font-size: .9rem;
  color: #fff;
  background-color: #0052cc;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.actionButton:hover {
  background-color: #0056b3;
}

.actionButton:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.actionButton:active {
  background-color: #003885;
}

.divider {
  height: 2px; 
  background-color: black;
  margin: 0;
}

.titleContainer {
  flex-grow: 1; /* allows the title container to grow and fill space */
}

.interactiveTitle {
  font-weight: 500;
  font-size: 1.5rem; /* Adjust size as needed */
  color: #0052cc; /* Ant Design primary color for the text */
  padding: 6px 15px; /* Padding to give it a button-like appearance */
  border: 1px solid #d9d9d9; /* Border similar to Ant Design */
  border-radius: 2px; /* Slight rounding of corners to match Ant Design buttons */
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); /* Subtle shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  cursor: default; /* Default cursor since it's not a button */
  background-color: #fff; /* Background color */
  margin: 0 auto; /* Centers the title if there's no flexbox alignment */
  white-space: nowrap; /* Keeps the text on a single line */
  overflow: hidden; /* Ensures the overflow is not visible */
  text-overflow: ellipsis; /* Adds an ellipsis (...) at the end of the truncated text */
}

.interactiveTitle:hover {
  color: #40a9ff; /* Lighter shade on hover */
  border-color: #40a9ff; /* Matching border color for consistency */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* More pronounced shadow on hover */
}
