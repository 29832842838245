.loadingScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Faded white background */
    z-index: 1000; /* Ensure it's above other content */
    opacity: 1; /* Make it fully visible */
    visibility: visible; /* Make it visible */
  }
  
  .loadingContent {
    text-align: center;
  }
  
  .loadingText {
    display: block; /* Text should be on its own line */
    margin-top: 16px;
  }
  