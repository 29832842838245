.site-layout-background {
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: sticky;
  transition: top 0.3s ease-in-out; /* This provides the smooth slide effect */
  top: 0;
  z-index: 100;
  align-items: center;
  height: 64px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,.1);
}

.header-logo {
  width: auto;
  height: 32px;
}

.header-title {
  font-size: 24px;
  color: #0052cc;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 48px;
  
  white-space: nowrap; /* Keeps the text on a single line */
  overflow: hidden; /* Ensures the overflow is not visible */
  text-overflow: ellipsis; /* Adds an ellipsis (...) at the end of the truncated text */
}

.site-layout-background a {
  cursor: pointer;
}

.site-layout-background img {
  max-width: 64px;
  height: auto;
}

.site-layout-background .ant-typography {
  margin-left: 5rem;
}

.site-layout-background .custom-menu .ant-menu-item:hover {
  background-color: #0052cc;
}

.site-layout-background .custom-menu .ant-menu-item:hover a {
  color: #fff;
}

.site-layout-background .custom-menu .ant-menu-item-selected {
  background-color: #0052cc;
}

.site-layout-background .custom-menu .ant-menu-item-selected a {
  color: #fff;
}

.header-hidden {
  top: -64px; /* Adjust this value to match your header's height */
}
