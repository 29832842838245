@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.textContainer {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.typeAnimation {
  font-size: 24px;
  color: #1890ff;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  animation: typing 5s steps(100) infinite;
}

.blink {
  animation: blinking 2s step-start infinite;
}

.carouselContainer {
  position: relative;
  height: fit-content;
  width: 50rem;
}

.flexContainer {
  display: flex;
  margin-top: 2rem;
  align-items: baseline;
  justify-content: flex-start;
}

.offerSection {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Add this line to vertically center the cards */
  margin-bottom: 16px;
}

.offerCard {
background: #f0f2f5;
height: 100%; /* Change this to 100% so the height is determined by the tallest item */
display: flex;
flex-direction: column;
justify-content: center; /* Keeps content vertically centered */
border-radius: 4px;
box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}


.offerImage {
  background-size: cover;
  background-position: center;
  height: 15rem;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: block;
}

.customList .customListItem {
  position: relative;
  padding-left: 24px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  transform-origin: left center;
  font-weight: 600;
}

.customList .customListItem::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border-radius: 50%;
}

.customList .customListItem:hover {
  transform: scaleX(1.05); 
}

.iconStyle {
  font-size: 24px;
  color: #0050b3;
  margin-bottom: 4px; 
}

.centerContainer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}

.headerTitle {
  font-size: 2rem;
  color: #0050b3;
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
}

.recordContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-image: url('../images/mokan.png');
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;
  height: 650px;
}

.carouselContainer {
  max-width: 90vw;
  height: auto;
  margin: 20px auto;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 5rem;
  position: relative; 
  overflow: visible;
}

.carouselItem {
  position: relative;
  width: 100%;
  max-height: 650px;
  overflow: hidden;
}

.carouselImage {
  display: block;
  width: 100%;
  max-height: 650px;
  max-width: 100%;
  height: auto;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.carouselItem:hover .carouselImage {
  transform: scale(1.1);
  opacity: 0.7;
}

.customArrow {
  position: absolute;
  top: 50%;
  z-index: 2;
  font-size: 24px;
  color: #333;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
  transform: translateY(-50%);
}

.customArrow:hover {
  background-color: #e8e8e8;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  width: 100%;
  transition: opacity 0.5s ease;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.carouselItem:hover .overlay {
  opacity: 1;
}

.statisticsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    align-items: end; /* Aligns items to the bottom */
    justify-content: center;
    background: #FFFFFF;
    padding: 20px;
    text-align: center;
    height: 250px;
    position: relative; /* Needed for absolute positioning of children */
  }
  
  .statisticItem {
    display: flex;
    flex-direction: column;
    position: absolute; /* Position items absolutely within the grid */
  }
  
  .statisticItem:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    align-self: start; /* Aligns first item to the top of its cell */
  }
  
  .statisticItem:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    align-self: center; /* Aligns second item to the center of its cell */
  }
  
  .statisticItem:nth-child(3) {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    align-self: end; /* Aligns third item to the bottom of its cell */
  }
  
  .statisticNumber {
    font-size: 48px;
    font-weight: bold;
    color: #333;
  }
  
  .statisticCaption {
    font-size: 14px;
    font-weight: 700;
    color: #4a90e2;
    font-family: Arial, sans-serif;
    margin-top: 5px;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
    flex-wrap: nowrap;
    width: 100%; /* Ensure the container takes full width */
  }
  
  .item {
    text-align: center;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .item img {
    width: 100%; /* Make image take up 100% of .item width */
    height: 30rem; /* Set a fixed height for the images */
    object-fit: cover; /* Ensure the image covers the area without distorting aspect ratio */
  }

  .header {
    text-align: center;
    font-size: 2.5rem; /* Example size, adjust as needed */
    color: #333; /* Dark grey color, can be adjusted */
    padding: 1rem 0; /* Spacing above and below the header */
    font-weight: bold;
    margin-bottom: 2rem; /* Spacing between the header and the images */
    width: 100%; /* Ensures it spans the full width */
    background-color: #f8f8f8; /* Light background, can be adjusted */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for a modern look */
  }

@media (max-width: 768px) {
  .statisticsContainer {
    flex-direction: column;
    padding: 10px;
  }

  .statisticItem {
    margin-bottom: 20px;
  }
  
  .statisticNumber {
    font-size: 5vw;
  }
  
  .offerSection {
    flex-direction: column;
  }

  .offerCard, .imageContainer {
    width: 100%;
    margin-bottom: 20px;
  }

  .customListItem {
    padding-left: 5%; /* Use percentage for padding */
    margin-bottom: 8px; /* Smaller margin */
    transform: none; /* Disable scaling effect on smaller screens */
    }
    .offerSection {
        grid-template-columns: 1fr;
      }
    
    /* Ensure the imageContainer takes full width below 768px */
    .imageContainer {
    width: 100%;
    }
}

@keyframes blinking {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

@keyframes typing {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}