.statistics-panel {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 4px;
    width: 300px;
    padding: 10px;
    transform: translateX(0%);
    transition: transform 0.3s ease-in-out;
  }
  
  .minimized-panel {
    transform: translateX(100%);
  }
  
  .show-panel-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1001;
  }
  
  .sidebar-visible .show-panel-btn {
    top: auto;
    bottom: 10px;
  }
  