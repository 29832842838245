.tool-panel {
  width: 350px; /* Fixed width or use flex-basis if you want it flexible */
  min-width: 250px; /* Minimum width */
  max-width: 400px; /* Maximum width */
  resize: horizontal;
  background-color: #f8f9fa; /* Light gray background for the panel */
  padding: 20px; /* Padding inside the panel */
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1); /* Right shadow for depth */
  z-index: 1; /* Above the map to cast a shadow */
  border-right: 1px solid #eaeaea; /* Border to separate from the map */
  overflow-y: auto;
}

.tool-panel::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.tool-panel::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.tool-panel::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tool-panel::-webkit-scrollbar {
  width: 8px;
}

.tool-panel h3 {
  color: #333;
  font-size: 18px;
  padding: 4px;
}

.tool-panel ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tool-panel li {
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.tool-panel li:last-child {
  border-bottom: none;
}

.tool-panel li:hover {
  background-color: #f5f5f5;
}

.tool-panel .tool-icon {
  margin-right: 10px;
}

.ant-btn-primary {
  background-color: #0052cc;
  transition: all 0.3s ease;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #40a9ff; /* Lighter shade for hover state */
  border-color: #40a9ff; /* Lighter shade for hover state */
}

.upload-container {
  background-color: rgba(0, 0, 0, 0.1); /* Shaded background color */
  border: 2px dashed #cccccc; /* Dashed border style */
  text-align: center; /* Center align the text and button */
}

.upload-text {
  margin: 0;
  font-size: 12px;
}

.file-list {
  margin-top: 10px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
  border-radius: 4px;
}

.file-item span {
  display: inline-block;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.file-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.file-delete-icon {
  color: red;
  cursor: pointer;
}

.file-delete-icon:hover {
  background-color: transparent;
}

.export-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.export-content span {
  margin-right: 16px;
}

.section-container {
  margin-top: 20px;
}

.party-data-container.modern {
  font-family: 'Segoe UI', 'Roboto', sans-serif;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  background: white;
  margin-top: 20px;
}

.party-data-header {
  padding: 10px 15px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.party-data-header h3 {
  margin: 0;
  font-size: 16px;
}

.party-data-content {
  padding: 15px;
}

.party-data-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #eaeaea;
  transition: background-color 0.3s;
}

.party-data-row:hover {
  background-color: #f9f9f9;
}

.party-data-row:first-child {
  border-top: none;
}

.party-data-name {
  margin-left: .5rem;
  flex-grow: 1;
}

.party-data-stats {
  display: flex;
  align-items: center;
}

.party-data-count {
  margin-right: .75rem;
}

.party-data-percentage {
  color: #555;
}

.map-point-count {
  display: block; /* or 'inline-block' depending on layout */
  margin-top: 10px; /* Adjust as needed for your layout */
  padding: 5px 10px;
  border-radius: 4px; /* Rounded corners */
  font-weight: 500;
  text-align: center;
  background-color: #0052cc; 
  color: #ffffff; /* White text for contrast */
  font-size: 14px;
}

.feature-insights-content p {
  background-color: #f2f2f2; /* A light background to distinguish the insights */
  padding: 10px;
  margin: 10px 0;
  border-left: 3px solid #ffcc00; /* A colored line to indicate significance */
  font-size: 14px;
}

.feature-insights-content p:first-of-type {
  border-left-color: #4CAF50; /* Different color for the top insight */
}

.feature-insights-content p:last-of-type {
  border-left-color: #f44336; /* Different color for alerts */
}
