.campaignLayout {
    background-color: #f9f9f9;
    min-height: 100vh;
    display: flex;
}

.portfolioContainer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    overflow: scroll;
}

.flexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.portfolioItem {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0 10px;
    width: 50%;
}

pre {
    background-color: #1e1e1e;
    color: #d4d4d4;
    padding: 16px;
    border-radius: 5px;
    overflow: auto;
    white-space: pre-wrap;
    max-height: 50rem;
}

.sourceLink {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 800;
}
