.typing-effect {
  font-size: 2rem;
  color: #0052cc;
  font-weight: bold;
  text-transform: uppercase;
}

.typing-effect::after {
  content: '|';
  animation: blink 1s infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}
  