/* ShapeSelectorModal.css */
.shape-selection-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .shape-row {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
  }
  