@import "leaflet.fullscreen/Control.FullScreen.css";

.mapping-container {
  display: flex;
  width: 100%;
  position: relative;
}

/* Ensure the MapContainer has a flex property */
.leaflet-container {
  flex: 1;
}

.custom-tooltip + .leaflet-tooltip-left:before,
.custom-tooltip + .leaflet-tooltip-right:before,
.custom-tooltip + .leaflet-tooltip-top:before,
.custom-tooltip + .leaflet-tooltip-bottom:before {
  border: none; /* Remove the default "pointy" part of the tooltip */
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  padding: 10px;
}

.remove-layers-control {
  margin-bottom: 40px; /* Adjust the margin to place it under the layer control */
  margin-right: 10px; /* Adjust as needed */
}

.remove-layers-button {
  background-color: #f8f9fa;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.remove-layers-button:hover {
  background-color: #e2e6ea;
  color: #212529;
}

.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #f8f9fa; /* Light gray background for the panel */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
  z-index: 400;
  transform: translateX(100%); /* Start off-screen to the right */
  transition: transform 1s ease-out; /* Animate the transform property */
}

.sidebar-visible {
  transform: translateX(0%);
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7); /* White with opacity */
  z-index: 1000; /* Should be higher than the map's z-index */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center text for the progress text */
}

.progress-text {
  margin-top: 20px; /* Space between progress bar and text */
}

.progress-info {
  width: 50%;
  text-align: center;
  margin-bottom: 10px; /* Space between text and progress bar */
}

.current-district {
  margin-bottom: 5px; /* Space between district text and progress bar */
}

.district-label {
  border: none; /* No border */
  font-size: 12px; /* Adjust the font size as needed */
  font-weight: bold; /* Make the text bold */
  color: black; /* Text color */
  text-align: center; /* Center the text within the divIcon */
}

.pill-button {
  height: 2rem; /* Adjust height to your preference */
  border-radius: 20px; /* Half of the height will give us a perfect pill shape */
}