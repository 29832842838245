.footer {
  background-color: #070b42;
  padding: 20px 50px;
  color: #f5f5f5;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.logoSection {
  margin-right: 20px;
}

.textSection {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonLikeText {
  color: whitesmoke;
  font-weight: 600;
  font-size: 1.2rem;
}

.contactInfo {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the start */
}

.contactItem {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 500;
}

.icon {
  color: #f5f5f5;
  font-size: 22px;
  margin-right: 8px;
}

.footerText {
  color: #FFF;
}

@media (max-width: 768px) {
  .footerContent {
    flex-direction: column;
    align-items: center;
  }

  .logoSection,
  .textSection,
  .contactInfo {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .contactItem {
    justify-content: center;
    margin-right: 0;
  }
}
