.mappingContainer {
    display: flex;
    width: 100%;
    position: relative;
}
  
.searchBoxContainer {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-100%);
    z-index: 1000;
}

.searchInput {
    width: 200px;
    border-radius: 20px;
    padding: 5px 10px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.coordinatesDisplay {
    position: absolute;
    top: 10px;
    right: 50px;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px 10px;
    border-radius: 20px;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
